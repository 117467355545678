






















































/**
 * 사용방법
 * 
 * <address-dialog
    ref="addressDialog"
    v-if="isAddressDialog"
    @cancel="isAddressDialog = false"
   ></address-dialog>
 * 
 * private onClickAddressField() {
    this.isAddressDialog = true;
    this.$nextTick(() => {
      this.$refs.addressDialog.addListenr((address: AddressData) => {
        this.data.zonecode = address.zonecode;
        this.data.sido = address.sido;
        this.data.sigungu = address.sigungu;
        this.data.address = address.address;
        this.data.address_detail = address.address_detail;
        this.isAddressDialog = false;
      });
    });
  }
 */
import { Vue, Component, Emit } from "vue-property-decorator";
import { VueDaumPostcode } from "vue-daum-postcode";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";

export interface AddressData {
  sido: string;
  sigungu: string;
  zonecode: string;
  address: string;
  address_detail: string;
}

export interface OnCompleteListener {
  (data: AddressData): void;
}

@Component({
  components: { VueDaumPostcode, ValidationTextField },
})
export default class AddressDialog extends Vue {
  @Emit()
  private cancel() {
    return null;
  }

  private visible = true;

  private data!: AddressData;
  private listener!: OnCompleteListener;

  private isSelectedAddress = false;

  private onSelectedAddress(result: AddressData) {
    this.isSelectedAddress = true;
    this.data = result;
  }

  public addListenr(listener: OnCompleteListener) {
    this.listener = listener;
  }

  private onClickBack() {
    this.isSelectedAddress = false;
  }

  private onClickSubmit() {
    this.listener(this.data);
  }
}
