






























import { Component, Watch, Emit, Prop } from "vue-property-decorator";
import { Org } from "@/models/Org";
import OrgApi from "@/apis/OrgApi";
import { BaseRepo } from "@/models/BaseRepo";
import BaseView from "@/views/BaseView.vue";
import { UserType } from "@/models/User";

@Component
export default class OrgSearchAndAdd extends BaseView {
  @Prop() userType!: UserType;
  @Prop() deviceType!: string;
  @Prop() categoryId: number | undefined;

  @Prop({ default: true })
  solo!: boolean;

  @Prop({ default: false })
  outlined!: boolean;

  private searchOrgName = "";

  private searchKeyword = ""; //검색어

  private serachResult: Org[] = [];

  private serachFlag = false;

  private api = new OrgApi();

  @Emit()
  private input(org: Org | null) {
    return org;
  }

  private resetFlag() {
    this.serachFlag = false;
  }

  private onSelectOrg(selected: Org) {
    if (selected != null) {
      this.input(selected);
    }
  }

  private result = {
    id: 0,
    name: "",
    org_category_id: 0,
    tel: "",
    sido: "",
    sigungu: "",
    address: "",
    address_detail: "",
    biz_num: "",
  } as Org;

  @Watch("searchKeyword")
  private async onChangeSearchKeyword(cur: string) {
    if (this.serachFlag) {
      return;
    }
    if (cur == null || cur.length < 1) return;
    if (this.isConnecting) return;

    //선택완료상태
    if (cur.includes("<") || cur.includes(">")) {
      return;
    }

    //완성되지 않은 한글이 있다면 리턴
    const checkHangul = /[ㄱ-ㅎ|ㅏ-ㅣ]/;

    if (checkHangul.test(cur)) return;

    this.isConnecting = true;
    try {
      const repo: BaseRepo = await this.api.searchByKeyword(
        cur,
        this.categoryId,
        this.userType
      );
      const orgs: Org[] = repo.data;

      this.serachResult = orgs;
    } catch (e) {
      this.handleError(e);
    }

    this.isConnecting = false;
  }

  get orgs() {
    return this.serachResult.map((org: Org) => {
      const sido = org.sido ?? "";
      const sigungu = org.sigungu ?? "";
      let address = "";

      if (sido !== "" && sigungu !== "") {
        address = `<${sido} ${sigungu}>`;
      }

      org.name = `${org.name}${address}`;

      return org;
    });
  }

  private onClickNew() {
    this.serachResult = [];

    const newPartner = {
      id: -1,
      name: "",
      org_category_id: 0,
      tel: "",
      sido: "",
      sigungu: "",
      address: "",
      address_detail: "",
      biz_num: "",
    } as Org;
    // this.serachResult.push(newPartner);
    this.serachFlag = true;
    this.input(newPartner);
  }
}
