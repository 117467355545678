import Vue from "vue";

export default class ClientApi {
  public readonly root: string = "clients";

  public index(params: any) {
    return Vue.axios.get(this.root, { params });
  }

  public create(params: any) {
    return Vue.axios.post(this.root, params);
  }

  public createOrg(params: any) {
    return Vue.axios.post(this.root + "/org", params);
  }

  public partnerAgreement(params: any) {
    return Vue.axios.post(this.root + "/agreement", params);
  }

  public partnerRevocation(id: number) {
    return Vue.axios.delete(this.root + "/revocation/" + id);
  }
}
