






























































































import ClientApi from "@/apis/care/ClientApi";
import OrgSearch from "@/components/ui/OrgSearch.vue";
import { Org } from "@/models/Org";
import Pagenation from "@/models/Pagenation";
import ArrayUtil from "@/utils/ArrayUtil";
import BaseView from "@/views/BaseView.vue";
import { Component } from "vue-property-decorator";
import CreateForm from "./forms/CreateForm.vue";
import { OrgOrgItems } from "@/views/models/admin/OrgOrgItems";

@Component({
  components: {
    CreateForm,
    OrgSearch,
  },
})
export default class Main extends BaseView {
  private facilitys: any = [];
  private clientListHeaders: any = [
    { text: "ID", value: "org.id" },
    {
      text: "고객명",
      value: "org.name",
      sortable: false,
    },
    {
      text: "주소",
      value: "address",
      sortable: false,
    },
    {
      text: "휴대전화",
      value: "org.tel",
      sortable: false,
    },
    {
      text: "사업자번호",
      value: "org.biz_num",
      sortable: false,
    },
    { text: "계약상태", value: "bookMark", sortable: false },
  ];

  private userType = "";
  private searchKeyword = "";
  private isCreating = false;

  private userOrdId = 0;
  private pagenation = new Pagenation();
  private selectedOrg!: Org;
  private clientApi: ClientApi = new ClientApi();
  private items: OrgOrgItems[] = [];

  protected created() {
    this.loadDatas(1);
  }

  private async loadDatas(page: number) {
    this.items = [];
    try {
      const params = {
        org_id: this.selectedOrg?.id,
        searchKeyword: this.searchKeyword,
        per_page: 10,
        page,
      };

      const repo = await this.clientApi.index(params);
      repo.data.per_page = Number(repo.data.per_page);
      this.pagenation = repo.data;
      this.items = [...this.pagenation.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private onSelectOrg(org: Org) {
    this.selectedOrg = { ...org };
    this.searchKeyword = this.selectedOrg.name;
    this.loadDatas(1);
  }

  private onClickCreated() {
    this.isCreating = true;
  }
  private onClickConfirm(items: OrgOrgItems, state: boolean) {
    if (state) {
      this.$confirm(
        "계약업체등록",
        "해당 시설을 계약 업체로 등록하시겠습니까?",
        async (result: boolean) => {
          if (!result) return;
          try {
            const repo = await this.clientApi.partnerAgreement(items);
            items.partner_id = repo.data.partner_id;
            items.client_id = repo.data.client_id;
            items.bookMark = "0";
            ArrayUtil.replaceById(this.items, items);
          } catch (e) {
            this.handleError(e);
          }
        }
      );
    } else {
      this.$confirm(
        "계약업체해제",
        "해당 시설에 등록을 해제하시겠습니까?",
        async (result: boolean) => {
          if (!result) return;
          try {
            await this.clientApi.partnerRevocation(items.client_id);
            ArrayUtil.removeById(this.items, items);
          } catch (e) {
            this.handleError(e);
          }
        }
      );
    }
  }

  private onSubmitOrg(data: OrgOrgItems) {
    this.isCreating = false;
    const items = [...this.items];
    const idx = items.findIndex((item) => item.client_id === data.client_id);

    if (idx === -1) {
      items.push(data);
    }

    this.items = items;
  }
}
